import React, { useEffect } from "react";
import Calendar from 'moedim';
import { useState} from "react";
import Schedule from "./schedule.js";
import parse from 'html-react-parser';
import styled from 'styled-components';

function Calendar2() {

  let currentVersion = localStorage.getItem("version");
  console.log(currentVersion);
  //if (currentVersion == null) {
  //  localStorage.setItem("version", "ESV");
  //  currentVersion = localStorage.getItem("version");
  //}
  var date = new Date();
  var now = date.setDate(date.getDate());
  var initial
  var now2 = new Date().toLocaleDateString('en-us', {month:"long", day:"numeric"});
  if (now2 in Schedule) {
    initial = now2 + " <a href='/" + currentVersion + "/" + Schedule[now2] + "'>" + Schedule[now2] + "</a>";
    initial = initial.replace("+", " ");
  } else {
    initial = "No Scheduled Reading for " + now2
  }
  console.log(now);
  const [value, setValue] = useState(initial);
  const url = "/ESV/"
  const StyledCalendar = styled(Calendar)`
  align-items: center;
  color: palevioletred;
  moedim-primary: #f00;
`;


  function test(d) {
    console.log(d.toLocaleDateString('en-us', {month:"long", day:"numeric"}));
    console.log(Schedule);
    if (d.toLocaleDateString('en-us', {month:"long", day:"numeric"}) in Schedule) {
      var value = (Schedule[d.toLocaleDateString('en-us', {month:"long", day:"numeric"})]).replace("+", " ");
      //console.log("TESTING....", value);
      //console.log("AM I EVEN GETTIGN HERE" + Schedule[d.toLocaleDateString('en-us', {month:"long", day:"numeric"})]);
      setValue(d.toLocaleDateString('en-us', {month:"long", day:"numeric"}) + ": <a href='/" + currentVersion + "/" + Schedule[d.toLocaleDateString('en-us', {month:"long", day:"numeric"})] + "'>" + value + "</a>");

    }
    else {
      setValue("No Scheduled Reading for " + d.toLocaleDateString('en-us', {month:"long", day:"numeric"}))
    }
  }

  return (
    <div className="calendar">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-sm-5">
            <StyledCalendar value={date} onChange={(d) => test(d)} />
          </div>
          <div class="col-sm-5">
            <h1 class="font-weight-light">Schedule</h1>
            <p>
              {parse(JSON.stringify(value).replace(/(^"|"$)/g, '').replace("+", " "))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar2;