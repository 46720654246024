import React from "react";
import ReactDOM from "react-dom/client";
import App from "../App.js";

function Search() {
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-5">
            <p>
            TESTING
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />)
export default Search;