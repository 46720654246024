var Schedule = {"May 1":"Luke+9-10",
                "May 2":"Luke+11-12",
                "May 3":"John+13-14",
                "May 4":"Luke+15-16",
                "May 5":"Luke+17-18",
                "May 8":"Luke+19-20",
                "May 9":"Luke+21-22",
                "May 10":"Luke+23-24",
                "May 11":"Numbers+1-3",
                "May 12":"Numbers+4-6",
                "May 15":"Numbers+7-9",
                "May 16":"Numbers+10-12",
                "May 17":"Numbers+13-15",
                "May 18":"Numbers+16-18",
                "May 19":"Numbers+19-21",
                "May 22":"Numbers+22-24",
                "May 23":"Numbers+25-27",
                "May 24":"Numbers+28-30",
                "May 25":"Numbers+31-33",
                "May 26":"Numbers+34-36",
                "May 29":"John+1-2",
                "May 30":"John+3-4",
                "May 31":"John+5-6",
                "June 1":"John+7-8",
                "June 2":"John+9-10",
                "June 5":"John+11-12",
                "June 6":"John+13-14",
                "June 7":"John+15-16",
                "June 8":"John+17-18",
                "June 9":"John+19-20",
                "June 12":"John+21",
                "June 13":"Deuteronomy+1-2",
                "June 14":"Deuteronomy+3-4",
                "June 15":"Deuteronomy+5-6",
                "June 16":"Deuteronomy+7-8",
                "June 19":"Deuteronomy+9-10",
                "June 20":"Deuteronomy+11-12",
                "June 21":"Deuteronomy+13-14",
                "June 22":"Deuteronomy+15-16",
                "June 23":"Deuteronomy+17-18",
                "June 26":"Deuteronomy+19-20",
                "June 27":"Deuteronomy+21-22",
                "June 28":"Deuteronomy+23-24",
                "June 29":"Deuteronomy+25-26",
                "June 30":"Deuteronomy+27-28",
                "July 3":"Deuteronomy+29-30",
                "July 4":"Deuteronomy+31-32",
                "July 5":"Deuteronomy+33-24",
                "July 6":"Joshua+1-2",
                "July 7":"Joshua+3-4",
                "July 10":"Joshua+5-6",
                "July 11":"Joshua+7-8",
                "July 12":"Joshua+9-10",
                "July 13":"Joshua+11-12",
                "July 14":"Joshua+13-14",
                "July 17":"Joshua+15-16",
                "July 18":"Joshua+17-18",
                "July 19":"Joshua+19-20",
                "July 20":"Joshua+21-22",
                "July 21":"Joshua+23-24",
                "July 24":"Acts+1-2",
                "July 25":"Acts+3-4",
                "July 26":"Acts+5-6",
                "July 27":"Acts+7-8",
                "July 28":"Acts+9-10",
                "July 31":"Acts+11-12",
                "August 1":"Acts+13-14",
                "August 2":"Acts+15-16",
                "August 3":"Acts+17-18",
                "August 4":"Acts+19-20",
                "August 7":"Acts+21-22",
                "August 8":"Acts+23-24",
                "August 9":"Acts+25-26",
                "August 10":"Acts+27-28",
                "August 11":"Judges+1-2",
                "August 14":"Judges+3-4",
                "August 15":"Judges+5-6",
                "August 16":"Judges+7-8",
                "August 17":"Judges+9-10",
                "August 18":"Judges+11-12",
                "August 21":"Judges+13-14",
                "August 22":"Judges+15-16",
                "August 23":"Judges+17-18",
                "August 24":"Judges+19-21",
                "August 25":"Ruth+1-4",
                "August 28":"1 Samuel+1-2",
                "August 29":"1 Samuel+3-4",
                "August 30":"1 Samuel+5-6",
                "August 31":"1 Samuel+7-8"}; 

export default Schedule;