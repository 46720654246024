import logo from './logo.svg';
import './App.css';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
//import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./navigation/navbar.js";
import Foots from "./navigation/footer.js";
import {searchInfo} from "./navigation/navbar.js";
import { json } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NLT from "./navigation/nlt.js";
import KJV from "./navigation/kjv.js";

const config = require("./config.js");
//get date
//let now = new Date().toLocaleDateString('en-us', {month:"long", day:"numeric"});

//dictionary of working days to chapters
var dict = {"January 2":"Matthew+1",
            "January 3":"Matthew+2",
            "December 31":"John+11", //test case
            "January 4":"Matthew+3",
            "January 5":"Matthew+4",
            "January 6":"Matthew+5",
            "January 9":"Matthew+6",
            "January 10":"Matthew+7",
            "January 11":"Matthew+8",
            "January 12":"Matthew+9",
            "January 13":"Matthew+10",
            "January 16":"Matthew+11",
            "January 17":"Matthew+12",
            "January 18":"Matthew+13",
            "January 19":"Matthew+14",
            "January 20":"Matthew+15",
            "January 23":"Matthew+16",
            "January 24":"Matthew+17",
            "January 25":"Matthew+18",
            "January 26":"Matthew+19",
            "January 27":"Matthew+20",
            "January 30":"Matthew+21",
            "January 31":"Matthew+22",
            "February 1":"Matthew+23",
            "February 2":"Matthew+24",
            "February 3":"Matthew+25",
            "February 6":"Matthew+26",
            "February 7":"Matthew+27",
            "February 8":"Matthew+28",
            "February 9":"Mark+1",
            "February 10":"Mark+2",
            "February 13":"Mark+3",
            "February 14":"Mark+4",
            "February 15":"Mark+5",
            "February 16":"Mark+6",
            "February 17":"Mark+7",
            "February 20":"Mark+8",
            "February 21":"Mark+9",
            "February 22":"Mark+10",
            "February 23":"Mark+11",
            "February 24":"Mark+12",
            "February 27":"Mark+13",
            "February 28":"Mark+14",
            "March 1":"Mark+15",
            "March 2":"Mark+16",
            "March 3":"Luke+1",
            "March 6":"Luke+2",
            "March 7":"Luke+3",
            "March 8":"Luke+4",
            "March 9":"Luke+5",
            "March 10":"Luke+6",
            "March 13":"Luke+7",
            "March 14":"Luke+8",
            "March 15":"Luke+9",
            "March 16":"Luke+10",
            "March 17":"Luke+11",
            "March 20":"Luke+12",
            "March 21":"Luke+13",
            "March 22":"Luke+14",
            "March 23":"Luke+15",
            "March 24":"Luke+16",
            "March 27":"Luke+17",
            "March 28":"Luke+18",
            "March 29":"Luke+19",
            "March 30":"Luke+20",
            "March 31":"Luke+21",
            "April 3":"Luke+22",
            "April 4":"Luke+23",
            "April 5":"Luke+24",
            "April 6":"John+1",
            "April 7":"John+2",
            "April 10":"John+3",
            "April 11":"John+4",
            "April 12":"John+5",
            "April 13":"John+6",
            "April 14":"John+7",
            "April 17":"John+8",
            "April 18":"John+9",
            "April 19":"John+10",
            "April 20":"John+11",
            "April 21":"John+12",
            "April 24":"John+13",
            "April 25":"John+14",
            "April 26":"John+15",
            "April 27":"John+16",
            "April 28":"John+17"}; 

function App() {
  var currentVerse = localStorage.getItem("currentVerse");
  let now = new Date().toLocaleDateString('en-us', {month:"long", day:"numeric"});
  const [date, setDate]  = useState(now);
  const [previous, setPrevious] = useState();
  const [next, setNext] = useState();
  const [verse, setVerse] = useState([]);
  const [error, setError] = useState(null);
  let count = 0;
  
  //set local storage value for saving info about bible version
  //default set to 'ESV'
  
  useEffect(() => {
    if (currentVerse) {
      localStorage.setItem("version", "ESV");
      var verse = currentVerse;
      localStorage.setItem("currentVerse", verse);
    } else if (date in dict) {
      var verse = dict[now];
      localStorage.setItem("currentVerse", verse);
    } else {
      setVerse("No Scheduled Reading Today.");
    }
      localStorage.setItem("currentVerse", verse);
      const url = "https://api.esv.org/v3/passage/html/?q=" + verse;
      axios.get(url, {
      headers: {
        "Authorization": config.getESV()

      }
    })
      .then((response) => {
        console.log(response.data);
        setPrevious((JSON.stringify(response.data.passage_meta[0].prev_chapter[0])) + "-" + (JSON.stringify(response.data.passage_meta[0].prev_chapter[1])));
        setNext((JSON.stringify(response.data.passage_meta[0].next_chapter[0])) + "-" + (JSON.stringify(response.data.passage_meta[0].next_chapter[1])));
        setVerse(response.data.passages[0]);
      })
      .catch((error) => {
        console.error(error);
      })
    
  }, [count, date]);
  const bodyStyle = {
  };

  if (previous) {
    return (
      <div className="App">
        <div class="container">
          <div class="row">
            <div class="col-sm-1">
                <a href={"/ESV/" + previous}>prev</a>
            </div>
            <div class="col-sm-5">
                Today: {date}
            </div>
            <div class="col-sm-1 align-items-right">
              <a href={"/ESV/" + next}>next</a>
            </div>
          </div>
          
          <div>
            {parse(JSON.stringify(verse).replace(/\\n/g, '\n').replace(/(^"|"$)/g, '').replaceAll('\\',''))}
          </div>
        </div>
        
      </div>
    )
  }
  else {
    return (
      <div className="App">
        <body style={bodyStyle}>
        <div>
        
        {parse(JSON.stringify(verse).replace(/\\n/g, '\n').replace(/(^"|"$)/g, '').replaceAll('\\',''))}
        </div>
      
        </body>
      </div>
    )
  }
  
}

export default App;
