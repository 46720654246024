import React from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useState } from 'react';
import parse from 'html-react-parser';

const config = require("../config.js");

function Passage() {

  
  const [passage, setPassage] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const params = useParams();
  var verse = params.passage;
  localStorage.setItem("currentVerse", verse);
      const url = "https://api.esv.org/v3/passage/html/?q=" + verse;
      axios.get(url, {
      headers: {
        "Authorization": config.getESV()
      }
    })
      .then((response) => {
        setPrevious((JSON.stringify(response.data.passage_meta[0].prev_chapter[0])) + "-" + (JSON.stringify(response.data.passage_meta[0].prev_chapter[1])));
        setNext((JSON.stringify(response.data.passage_meta[0].next_chapter[0])) + "-" + (JSON.stringify(response.data.passage_meta[0].next_chapter[1])));
        setPassage(response.data.passages[0]);
      })
      .catch((error) => {
        console.error(error);
      })
  return (
    <div className="passage">
      <div class="container">
      <div class="row align-items-center my-5">
      <div class="col-sm-1">
          <a href={"/ESV/" + previous}>prev</a>
        </div>
        <div class="col-sm-8"></div>
        <div class="col-sm-1">
        <a href={"/ESV/" + next}>next</a>
        </div>
        </div>
          <div>
          {parse(JSON.stringify(passage).replace(/\\n/g, '\n').replace(/(^"|"$)/g, '').replaceAll('\\',''))}
          </div>
        
      </div>
    </div>
  );
}

export default Passage;