import React, { useEffect } from "react";
import { useState, useContext} from "react";
import App from '../App.js';
import axios from 'axios';
import parse from 'html-react-parser';
import ReactDOM from "react-dom/client";
const config = require("../config.js");


const Navbar = () => {

    let now = new Date().toLocaleDateString('en-us', {month:"short", day:"numeric"});
    //var currentVersion = localStorage.getItem("version");
    const [currentVersion, setCurrentVersion] = useState(localStorage.getItem("version"));
    const [searchInfo, setSearchInfo] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    let el = [];
    const handleChange = (event) => {
        setSearchInfo({"search": event.target.value });
        console.log(searchInfo);
    }
    const onClick = (event) => {
      console.log(event);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        var searchTerm = searchInfo.search;
        const url = "https://api.esv.org/v3/passage/search/?q=" + searchTerm;
      axios.get(url, {
      headers: {
        "Authorization": config.getESV()
        }
      })
      .then((response) => {
        //console.log(response.data.results);
        const valuesArray = response.data.results;
        const personsObject = JSON.parse(JSON.stringify(valuesArray));
        const personsMap = new Map(Object.entries(personsObject));
        var searchContent = "<h1>Search Results</h1>";
        for (let [key, value] of personsMap) {
          console.log(value.content);
          var full_passage = value.reference.split(":")[0];
          searchContent += "<div><a href='/" + currentVersion + "/" + full_passage + "'>" + value.reference + "</a> " + value.content + "</div>"
          el.push(value.content);
        }
        setSearchResults(searchContent);
        
        //setVerse(response.data.passages[0]);
      })
      .catch((error) => {
        console.error(error);
      })
        //actually kick off function that will send api call.
        //console.log(addSearch);
    };

    if (searchResults[0] != null) {
      var currentVerse = localStorage.getItem("currentVerse");
      var currentVersion_Href = "/" + currentVersion + "/" + currentVerse;
      return (
        <div>
        <nav class="navbar navbar-expand-sm navbar-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
              <img src="/word520-Logo.png" height="75" alt=""></img>
          </a>
          <button class="navbar-toggler hidden-md-up pull-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon "></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" href="/Calendar">Calendar</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Version
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" onclick="localStorage.setItem('version', 'ESV'); console.log('IT WAS CLICKED'); " href="/ESV/">ESV</a></li>
                  <li><a class="dropdown-item" onclick="localStorage.setItem('version', 'NLT'); console.log('IT WAS CLICKED'); " href="/NLT/">NLT</a></li>
                  <li><a class="dropdown-item" onclick="localStorage.setItem('version', 'KJV'); console.log('IT WAS CLICKED'); " href="/KJV/">KJV</a></li>
                </ul>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/About">About</a>
              </li>
            </ul>
            <form class="d-flex" role="search" onSubmit={handleSubmit}>
              <input class="form-control me-2" type="text" placeholder="Search" aria-label="Search" onChange={handleChange} value={searchInfo.search}></input>
              <button id="search-btn" class="btn btn-outline-primary" type="submit">Search</button>
            </form>
          </div>
        </div>
      </nav>
      <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-5">
              {parse(JSON.stringify(searchResults).replace(/(^"|"$)/g, '').replace("undefined", '').replace("[]", ""))}
          </div>
        </div>
      </div>
    </div>
    </div>
      )
    }
    else {
    var currentVerse = localStorage.getItem("currentVerse");
    var currentVersion_Href = "/" + currentVersion + "/" + currentVerse;
    return (
        <nav class="navbar navbar-expand-sm navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
        <img src="/word520-Logo.png" height="75" alt=""></img>
    </a>
    <button class="navbar-toggler hidden-md-up pull-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon "></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="/Calendar">Calendar</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Version
          </a>
          <ul class="dropdown-menu">
            <li><h6>Version</h6></li>
            <li><div class="dropdown-divider"></div></li>
            <li><a class="dropdown-item" href="/ESV/">ESV</a></li>
            <li><a class="dropdown-item" href="/NLT/">NLT</a></li>
            <li><a class="dropdown-item" href="/KJV/">KJV</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/About">About</a>
        </li>

      </ul>
      <form class="d-flex" role="search" onSubmit={handleSubmit}>
        <input class="form-control me-2" type="text" placeholder="Search" aria-label="Search" onChange={handleChange} value={searchInfo.search}></input>
        <button id="search-btn" class="btn btn-outline-primary" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
    )
    }
};


export default Navbar; //default Navbar;