import React from "react";
import ReactDOM from 'react-dom/client';

function Landing() {
  return (
    <div className="about">
      <div class="container">
            <h2>Welcome</h2>
            <p>Are you looking for a way to read the entire Bible in two years? Our word520 plan is perfect for you. By committing to daily readings, you'll be able to work your way through the entire Bible in just two years. Plus, with the convenience of our app, you can access your readings anytime, anywhere. Start your journey towards deeper understanding of God's Word today with our word520 plan.</p>
            <h2>How it works:</h2>
            <li>1. Subscribe for Daily Email Reminders</li>
            <li>2. Click on the link in your email reminder to visit word520.com</li>
            <li>3. Read Today's Scheduled Chapters - Finish the entire Bible in 2 Years!</li>
      </div>
    </div>
  )
}

export default Landing;
