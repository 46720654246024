import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./navigation/about.js";
import Calendar2 from "./navigation/calendar.js";
import Navbar from "./navigation/navbar.js";
import Foots from "./navigation/footer.js";
import Search from "./navigation/search.js";
import Passage from "./navigation/passage.js";
import NLT from "./navigation/nlt.js";
import KJV from "./navigation/kjv.js";
import Test from "./navigation/test.js";
import Landing from "./navigation/landing.js";
import Login from "./navigation/login";
import Dashboard from "./navigation/dashboard.js";
import { AuthProvider } from 'react-auth-kit';

let currentVersion = localStorage.getItem("version");
  if (currentVersion == undefined) {
    localStorage.setItem("version", "ESV");
    currentVersion = localStorage.getItem("version");
  }
  console.log(currentVersion);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <Navbar/>
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure
    >
      <Routes>
      <Route path="/login" element={<Login />} />
        <Route path="/dash" element={<Dashboard />} />
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/calendar" element={<Calendar2 />} />
        <Route path="/today" element={<Test />} />
        <Route path="/ESV/" element={<App />} />
        <Route path="/NLT/" element={<NLT />} />
        <Route path="/KJV/" element={<KJV />} />
        <Route path='/search' element={<Search />} />
        <Route path='/ESV/:passage' element={<Passage />} />
        <Route path='/NLT/:passage' element={<NLT />} />
        <Route path='/KJV/:passage' element={<KJV />} />
    </Routes>
    </AuthProvider>
    <Foots />
    </Router>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
