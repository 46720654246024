import React from "react";

const foots = () => {
    return (
    <footer>
    <div class="text-center p-3">
        © 2022 Copyright:
        <a class="text-dark" href="https://www.word520.com"> word520.com</a>
    </div>
    </footer>
    )
};
export default foots