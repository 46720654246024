import React from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import '../index.css';
import Schedule from "./schedule.js";

const nlt_config = require("../nlt_config.js");
const config = require("../config.js");

function Kjv() {

  localStorage.setItem("version", "KJV");
  var currentVerse = localStorage.getItem("currentVerse");
  let now = new Date().toLocaleDateString('en-us', {month:"long", day:"numeric"});
  const [date, setDate]  = useState(now)
  const [passage, setPassage] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const params = useParams();
  
  useEffect(() => {
  if (params.passage) {
    var verse = params.passage;
  }  else if (currentVerse) {
    var verse = currentVerse;
  } else if (date in Schedule) {
    var verse = Schedule[now];
  }
  if (verse != null) {
  localStorage.setItem("currentVerse", verse);
  var nlt_api_key = nlt_config.getNLT(); 
      const url = "https://api.nlt.to/api/passages?version=KJV&ref=" + verse + "&key=" + nlt_api_key;
      axios.get(url)
      .then((response) => {
        console.log(response);
        //setPrevious((response.data.request));
        //setNext((JSON.stringify(response.data.passage_meta[0].next_chapter[0])) + "-" + (JSON.stringify(response.data.passage_meta[0].next_chapter[1])));
        setPassage(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
    } else {
      setPassage("No Scheduled Reading Today.");
    }


  }, [passage]);
  
  return (
    <div className="Kjv">
      <div class="container">
        <body>
          <div>
          {parse(JSON.stringify(passage).replace(/\\n/g, '\n').replace(/(^"|"$)/g, '').replaceAll('\\',''))}
          </div>
        </body>
      </div>
    </div>
  );
}

export default Kjv;