import React from "react";
import NLT from "./nlt.js";
import KJV from "./kjv.js";
import APP from "../App.js";

function Test() {
    var currentVersion = localStorage.getItem("version");
    localStorage.removeItem("currentVerse");
    console.log("BEEP BOOP", currentVersion);
    if (currentVersion === "NLT") {
      console.log("DID THIS AT LEAST RUN");
      return (
        <NLT/>
      )
    } else if (currentVersion === "KJV") {
      console.log("DID THIS AT LEAST RUN");
      return (
        <KJV/>
      )
    } else {
        return (
        <APP/>
        )
    }
  }

export default Test;