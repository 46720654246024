import React from "react";

function About() {
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-5">
            <h1 class="font-weight-light">About</h1>
            <p>
            Word520 is a Bible reading plan that helps you read through the entire Bible in 2 years. It is structured to be read five days a week, with weekends off (or for catching up), and is designed to make it easy for readers to engage with the Bible despite busy schedules.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;